



































import { Action } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import FormInput from '@/components/forms/Input.vue';
import axios from 'axios';

@Component({
    components: {
        PagePresetGuest: () => import('@/layouts/presets/Guest.vue'),
        FormBase: () => import('@/components/forms/Base.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        FormButton: () => import('@/components/forms/Button.vue'),
        ElButton: () => import('@/components/elements/Button.vue'),
    },
})
export default class PageRegister extends Vue {
    @Action('user/register') register!: (payload: RegisterPayload) => Promise<Auth>;

    i18n: string = 'views.register';

    page: number = 1;

    loading: boolean = false;

    success: boolean = false;

    serverError: ErrorResponse | boolean = false;

    $refs!: Vue['$refs'] & {
        registerForm: FormClass;
        first_name: FormInput;
        last_name: FormInput;
    };

    payload: RegisterPayload = {
        email: undefined,
        password: undefined,
        repeat_password: undefined,
        first_name: undefined,
        last_name: undefined,
        locale: 'nl_BE',
        invite_code: undefined,
    };

    mounted() {
        const urlParams = new URLSearchParams(window.location.search);

        const invite = urlParams.get('invite_code');
        if (invite != null) {
            this.payload.invite_code = invite;
        }

        if (this.payload.invite_code == null) {
            this.$router.push({ name: 'login' });
        } else {
            axios
                .get(`${process.env.VUE_APP_API_URL}invites/${invite}`)
                .then((response) => {
                    this.payload.email = response.data.data.prefilled_data.email;
                })
                .catch((e: ErrorResponse) => {
                    return Promise.reject(e);
                });
        }
    }

    handleErrors(errors: ErrorResponse) {
        this.loading = false;
        this.success = false;
        this.serverError = errors;
        return true;
    }

    handleSuccess() {
        this.success = true;
        this.loading = false;
        this.serverError = false;
        setTimeout(() => {
            this.success = false;
            return true;
        }, 2000);
    }

    private registerSubmit() {
        this.loading = true;
        this.success = false;
        this.serverError = false;

        this.register(this.payload)
            .then(() => {
                this.handleSuccess();
                this.$router.push({ name: 'login', query: { action: 'registered' } });
            })
            .catch((e: ErrorResponse) => this.handleErrors(e));
    }
}
